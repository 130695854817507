.geosuggest {
  input {
    width: 100%;
  }

  ul {
//    background-color: white;
    cursor: pointer;
  }

  :global(.geosuggest__suggests--hidden) {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }
  
  // :global(.geosuggest__item--active) {
  //   background-color: #267dc0;
  //   color: #fff;
  // }
}
